import * as yup from 'yup';
import {
  addressSchema,
  pubKeySchema,
  stakeAmountSchema,
  stakeIpSchema,
  stakeIpv6Schema,
  stakePortSchema,
} from './schema';

const stakeAndJoinNodeRequestSchema = yup.object({
  amount: stakeAmountSchema,
  ip: stakeIpSchema,
  ipv6: stakeIpv6Schema,
  port: stakePortSchema,
  nodeAddress: addressSchema,
  senderPubKey: pubKeySchema,
  receiverPubKey: pubKeySchema,
});

export default class StakeAndJoinNodeRequest {
  public readonly amount: string;
  public readonly ip: string;
  public readonly ipv6: string;
  public readonly port: string;
  public readonly nodeAddress: string;
  public readonly senderPubKey: string;
  public readonly receiverPubKey: string;

  constructor({
    amount,
    ip,
    ipv6,
    port,
    nodeAddress,
    senderPubKey,
    receiverPubKey,
  }: {
    amount: string;
    ip: string;
    ipv6: string;
    port: string;
    nodeAddress: string;
    senderPubKey: string;
    receiverPubKey: string;
  }) {
    this.amount = amount;
    this.ip = ip;
    this.ipv6 = ipv6;
    this.port = port;
    this.nodeAddress = nodeAddress;
    this.senderPubKey = senderPubKey;
    this.receiverPubKey = receiverPubKey;
  }

  async validate(): Promise<void> {
    try {
      await stakeAndJoinNodeRequestSchema.validate({
        amount: this.amount,
        ip: this.ip,
        ipv6: this.ipv6,
        port: this.port,
        nodeAddress: this.nodeAddress,
        senderPubKey: this.senderPubKey,
        receiverPubKey: this.receiverPubKey,
      });
    } catch (e: any) {
      throw new Error(`Invalid stake and join node request: ${e.message}`);
    }
  }
}
