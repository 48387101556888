import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import StakingAliasesInfoRequest from '../../models/StakingAliasesInfoRequest';
import { addAlias, removeAlias } from '../../services/staking';

function StakingAliasesPage() {
  const [addAliasAddress, setAddAliasAddress] = useState<string>('');
  const [removeAliasAddress, setRemoveAliasAddress] = useState<string>('');
  const [addValidationError, setAddValidationError] = useState<string | null>(
    null
  );
  const [removeValidationError, setRemoveValidationError] = useState<
    string | null
  >(null);

  const handleAddAliasAddress = async () => {
    try {
      // Construct request
      const request = new StakingAliasesInfoRequest({
        aliasAddress: addAliasAddress,
      });

      // Validate request
      try {
        await request.validate();
        setAddValidationError(null);
      } catch (error: any) {
        setAddValidationError(error.message);
        return;
      }

      // Send request
      await addAlias(addAliasAddress);
    } catch (e: any) {
      console.log('Error adding staking alias: ', e);
      alert('Error adding staking alias: ' + e.message);
    }
  };

  const handleRemoveAliasAddress = async () => {
    try {
      // Construct request
      const request = new StakingAliasesInfoRequest({
        aliasAddress: removeAliasAddress,
      });

      // Validate request
      try {
        await request.validate();
        setRemoveValidationError(null);
      } catch (error: any) {
        setRemoveValidationError(error.message);
        return;
      }

      // Send request
      await removeAlias(removeAliasAddress);
    } catch (e: any) {
      console.log('Error removing staking alias: ', e);
      alert('Error removing staking alias: ' + e.message);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{ mb: 2 }} variant={'h5'} align={'center'}>
          - Staking Aliases -
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardContent>
            <Stack spacing={2} sx={{ m: 2 }}>
              <Typography variant={'h6'}>Add Staking Alias Address</Typography>
              <TextField
                fullWidth
                size={'small'}
                value={addAliasAddress}
                onChange={(e) => setAddAliasAddress(e.target.value)}
                label={'Alias Address'}
              />
              {addValidationError && (
                <Typography color={'error'}>{addValidationError}</Typography>
              )}
              <Button
                variant={'contained'}
                onClick={handleAddAliasAddress}
                style={{
                  width: '15em',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              >
                Add Alias
              </Button>

              <Typography variant={'h6'}>
                Remove Staking Alias Address
              </Typography>
              <TextField
                fullWidth
                size={'small'}
                value={removeAliasAddress}
                onChange={(e) => setRemoveAliasAddress(e.target.value)}
                label={'Alias Address'}
              />
              {removeValidationError && (
                <Typography color={'error'}>{removeValidationError}</Typography>
              )}
              <Button
                variant={'contained'}
                onClick={handleRemoveAliasAddress}
                style={{
                  width: '15em',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              >
                Remove Alias
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default StakingAliasesPage;
