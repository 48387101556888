import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import {
  Network,
  RpcConfig,
  RpcConfigEntry,
} from '../../context/appContextInterfaces';
import { rpcConfigEntryHeaders, rpcConfigEntryUrl } from '../../models/schema';

/** These are the networks that we prevent editing against.   Yellowstone is not included because we need node ops to be able to edit these right now. */
const PROTECTED_NETWORK_KEYS: string[] = ['lit', 'litTestnet', 'vesuvius'];

function isNetworkProtected(networkKey: string) {
  return PROTECTED_NETWORK_KEYS.indexOf(networkKey) !== -1;
}

function RPCConfig({
  rpcConfig,
  setRpcConfig,
}: {
  rpcConfig: RpcConfig;
  setRpcConfig: any;
}) {
  return (
    <Card>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={'h6'}>RPC URLs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fragment>
            {!rpcConfig ? (
              <Typography variant={'h6'}>No RPC URLs found</Typography>
            ) : (
              <form>
                <Grid container spacing={1}>
                  {Object.entries(rpcConfig).map(
                    ([networkKey, rpcConfigEntries], idx: number) => {
                      return (
                        <Grid key={idx} item xs={12}>
                          <Stack direction={'row'}>
                            <Grid item xs={2}>
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant={'body1'}>
                                  {networkKey}
                                </Typography>
                                {!isNetworkProtected(networkKey) && (
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      // Add a new RpcConfigEntry for this networkKey.
                                      const newRpcConfig = {
                                        ...rpcConfig,
                                      };

                                      const newRpcConfigEntry: RpcConfigEntry =
                                        {
                                          url: '<YOUR_RPC_URL_HERE>',
                                        };

                                      newRpcConfig[networkKey as Network] = [
                                        ...newRpcConfig[networkKey as Network]!,
                                        newRpcConfigEntry,
                                      ];

                                      console.log('newRpcConfig', newRpcConfig);
                                      setRpcConfig(newRpcConfig);
                                    }}
                                  >
                                    Add Entry
                                  </Button>
                                )}
                              </Stack>
                            </Grid>
                            <Grid item xs={10}>
                              <Stack spacing={1}>
                                {rpcConfigEntries.map((rpcConfigEntry, idx) => {
                                  return RpcConfigEntryComponent(
                                    rpcConfigEntry,
                                    (value: string, field: FormField) => {
                                      const newRpcConfig = {
                                        ...rpcConfig,
                                      };
                                      const newRpcConfigEntry = {
                                        ...rpcConfigEntry,
                                      };

                                      if (field === 'url') {
                                        newRpcConfigEntry.url = value;
                                      } else if (field === 'headers') {
                                        newRpcConfigEntry.headers =
                                          JSON.parse(value);
                                      } else if (field === 'apikey') {
                                        newRpcConfigEntry.apikey = value;
                                      }

                                      newRpcConfig[networkKey as Network] = [
                                        ...newRpcConfig[
                                          networkKey as Network
                                        ]!.slice(0, idx),
                                        newRpcConfigEntry,
                                        ...newRpcConfig[
                                          networkKey as Network
                                        ]!.slice(idx + 1),
                                      ];

                                      console.log('newRpcConfig', newRpcConfig);
                                      setRpcConfig(newRpcConfig);
                                    },
                                    isNetworkProtected(networkKey)
                                  );
                                })}
                              </Stack>
                            </Grid>
                          </Stack>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </form>
            )}
          </Fragment>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

type FormField = 'url' | 'headers' | 'apikey';

function RpcConfigEntryComponent(
  rpcConfigEntry: RpcConfigEntry,
  handleFormChange: (value: string, field: FormField) => void,
  isProtectedNetwork: boolean = false
) {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <TextField
        name={'URL'}
        sx={{
          flexGrow: '1',
          flexShrink: '1',
          ml: 2,
        }}
        size={'small'}
        value={rpcConfigEntry.url}
        onChange={async (value) => {
          try {
            const newUrl = value.target.value.toString();
            await rpcConfigEntryUrl.validate(newUrl);
            handleFormChange(newUrl, 'url');
          } catch (e: any) {
            console.error('Invalid URL', e);
          }
        }}
        disabled={isProtectedNetwork}
      />
      <TextField
        name={'Headers'}
        sx={{
          flexGrow: '1',
          flexShrink: '1',
          ml: 2,
        }}
        size={'small'}
        value={
          !!rpcConfigEntry.headers
            ? JSON.stringify(rpcConfigEntry.headers)
            : null
        }
        onChange={async (value) => {
          try {
            const newHeaders = value.target.value.toString();
            await rpcConfigEntryHeaders.validate(newHeaders);
            handleFormChange(newHeaders, 'headers');
          } catch (e: any) {
            console.error('Invalid Headers', e);
          }
        }}
        disabled={isProtectedNetwork}
      />
      <TextField
        name={'API Key'}
        sx={{
          flexGrow: '1',
          flexShrink: '1',
          ml: 2,
        }}
        size={'small'}
        value={rpcConfigEntry.apikey}
        onChange={(value) => {
          handleFormChange(value.target.value, 'apikey');
        }}
        disabled={isProtectedNetwork}
      />
    </Stack>
  );
}

export default RPCConfig;
