import { Grid, Stack, Switch, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";

interface SwitchFormFieldProps {
  setValue: (value: ChangeEvent<HTMLInputElement>, type?: string | undefined) => void;
  value: boolean;
  label: string;
  name: string;
}

function SwitchFormField({setValue, value, label, name}: SwitchFormFieldProps) {
  return (
    <Grid item xs={6}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Typography sx={{flexBasis: '270px'}} align={'right'} variant={'body1'}>{label}</Typography>
        {/*<Checkbox checked={value} onChange={(e) => setValue(e.target.checked)}/>*/}
        <Switch name={name} checked={value} onChange={(e) => setValue(e, 'switch')}/>
      </Stack>
    </Grid>
  );
}

export default SwitchFormField;