import React, { ChangeEvent } from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";

interface TextFormFieldProps {
  setValue: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type?: string | undefined) => void;
  value: string;
  label: string;
  name: string;
}

export function TextFormField({setValue, value, label, name}: TextFormFieldProps) {
  return (
    <Grid item xs={6}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{flexBasis: '270px'}} align={'right'} variant={'body1'}>{label}</Typography>
        <TextField
          name={name}
          sx={{flexGrow: '1', flexShrink: '1', ml: 2}}
          size={'small'}
          value={value.toString()}
          onChange={(value) => {
            setValue(value, 'text');
          }}
        />
      </Stack>
    </Grid>
  );
}