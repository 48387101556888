import { useEffect, useState } from "react";
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import SetContractAddresses from "../../components/setContractAddresses/SetContractAddresses";
import StakingButtons from "../../components/stakingButtons/StakingButtons";
import StakeAndJoinNode from "../../components/stakeAndSetNodeInfo/StakeAndSetNodeInfo";
import GenerateCommunicationEncryptionKeys
  from "../../components/generateCommunicationEncryptionKeys/GenerateCommunicationsEncryptionKeys";
import { nodeWalletState, receiverComsKeyPairState, senderComsKeyPairState } from "../../context/appContext";
import { useRecoilState } from "recoil";
import GenerateNodeWallet from "../../components/generateNodeWallet/GenerateNodeWallet";

const steps = ['Generate Communication Encryption Keys', 'Generate Node Wallet', 'Stake and Join Node'];

function StakingPage() {
  const [senderComsKeyPair, setSenderComsKeyPair] = useRecoilState(senderComsKeyPairState);
  const [receiverComsKeyPair, setReceiverComsKeyPair] = useRecoilState(receiverComsKeyPairState);
  const [nodeWalletAddress, setNodeWalletAddress] = useRecoilState(nodeWalletState);

  const [activeStakingStep, setActiveStakingStep] = useState<number>(0);

  const handleNextStep = () => {
    if (activeStakingStep < steps.length - 1) {
      setActiveStakingStep(activeStakingStep + 1);
    }
  }

  const handlePreviousStep = () => {
    if (activeStakingStep > 0) {
      setActiveStakingStep(activeStakingStep - 1);
    }
  }

  const disableNextStepButton = () => {
    if (!senderComsKeyPair || !receiverComsKeyPair) {
      return true;
    }
    if (activeStakingStep === 1 && !nodeWalletAddress) {
      return true;
    }
    if (activeStakingStep === steps.length - 1) {
      return true;
    }
    return false;
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{ mb: 2 }} variant={'h5'} align={'center'}>- Staking -</Typography>
      </Grid>
      <Grid item sm={24}>
        <SetContractAddresses />
      </Grid>
      <Grid item sm={6}>
        {/*<StakeAndJoinNode/>*/}
        <Stepper sx={{ py: 2 }} activeStep={activeStakingStep} alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {activeStakingStep === 0 && <GenerateCommunicationEncryptionKeys />}
        {activeStakingStep === 1 && <GenerateNodeWallet />}
        {activeStakingStep === 2 && <StakeAndJoinNode />}
        <Box sx={{ p: 4, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button disabled={activeStakingStep === 0} onClick={handlePreviousStep}>Back</Button>
          <Button disabled={disableNextStepButton()}
            onClick={handleNextStep}>Next</Button>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <StakingButtons />
      </Grid>
    </Grid>
  )
}

export default StakingPage;
