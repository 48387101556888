import { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, Card, CardContent } from "@mui/material";
import SetContractAddresses from "../../components/setContractAddresses/SetContractAddresses";
import { useRecoilState } from "recoil";
import { getSigner } from '../../lib/web3';
import { getNodeInfo } from "../../services/staking";
import { contractAddressesState } from "../../context/appContext";

function OnchainNodeInfoPage() {

    const [stakingWalletAddress, setStakingWalletAddress] = useState('')
    const [stakingContractAddress, _] = useRecoilState(contractAddressesState);
    const [nodeInfo, setNodeInfo] = useState('');

    useEffect(() => {
        setStakingWalletFromMetamask();
    }, [])

    const setStakingWalletFromMetamask = async () => {
        const signer = await getSigner();
        const stakingAddress = await signer.getAddress();
        setStakingWalletAddress(stakingAddress);
    }

    const handleGetNodeInfo = async () => {
        let nodeInfo = await getNodeInfo(stakingContractAddress.stakingAddress, stakingContractAddress.stakingBalancesAddress, stakingWalletAddress);
        setNodeInfo(nodeInfo)
    }

    return (
        <Grid container spacing={2}>
            <Grid item sm={24}>
                <Typography sx={{ mb: 2 }} variant={'h5'} align={'center'}>- Check Node Info -</Typography>
            </Grid>
            <Grid item sm={24}>
                <SetContractAddresses />
            </Grid>
            <Grid item sm={24}>
                <Typography sx={{ mb: 2 }} variant={'h6'} align={'center'}>Check the info set for your node in the staking contract.  The address in the field below must match the staking wallet you used previously.</Typography>
                <Card><CardContent>
                    <TextField
                        sx={{ flexGrow: '1', flexShrink: '1' }}
                        fullWidth
                        value={stakingWalletAddress}
                        onChange={(e) => setStakingWalletAddress(e.target.value)}
                        label={'Staking Wallet Address'}
                        placeholder={'eg. 0xf899FcCE61F48211aa9b36Dc39093951E6028698'}
                    />
                    <Button onClick={handleGetNodeInfo} variant='contained'>Get Node Info</Button>
                </CardContent></Card>

            </Grid>
            <Grid item sm={24}>
                <pre>{JSON.stringify(nodeInfo, null, 2)}</pre>
            </Grid>
        </Grid>
    )
}

export default OnchainNodeInfoPage;