import { ethers } from 'ethers';
import { string } from 'yup';
import { ip2int } from '../utils';

export const stakeAmountSchema = string().test({
  name: 'stake-amount',
  message: ({ value }) => `'${value}' is not a valid stake amount`,
  test: (value) => {
    // First transform string into BigNumber.
    const amount = ethers.BigNumber.from(value);

    // Amount must be greater than 0.
    if (amount.lte(0)) {
      return false;
    }

    return true;
  },
});

export const stakeIpSchema = string()
  .test({
    name: 'stake-ip',
    message: ({ value }) => `'${value}' is not a valid stake ip`,
    test: (value) => {
      // First transform string into integer.
      const ip = ip2int(value!);

      // IP must be greater than 0.
      if (ip <= 0) {
        return false;
      }

      return true;
    },
  })
  .required();

export const stakeIpv6Schema = string().required();

export const stakePortSchema = string().required();

export const addressSchema = string()
  .test({
    name: 'address',
    message: ({ value }) => `'${value}' is not a valid address`,
    test: (value) => {
      return ethers.utils.isAddress(value!);
    },
  })
  .required();

export const pubKeySchema = string()
  .test({
    name: 'pub-key',
    message: ({ value }) => `'${value}' is not a valid pub key`,
    test: (value) => {
      // Check if value is a valid hex string.
      if (!ethers.utils.isHexString(value!)) {
        return false;
      }

      // Check if value is a valid public key.
      try {
        ethers.utils.computePublicKey(value!);
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    },
  })
  .required();

export const rpcConfigEntryUrl = string().url().required();
export const rpcConfigEntryHeaders = string().test({
  name: 'rpc-config-entry-headers',
  message: ({ value }) => `'${value}' is not a parseable JSON string`,
  test: (value) => {
    try {
      JSON.parse(value ?? '');
    } catch (e) {
      return false;
    }

    return true;
  },
});
