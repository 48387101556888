import { Grid, Typography } from '@mui/material';
import SetContractAddresses from '../../components/setContractAddresses/SetContractAddresses';
import ActivateNodeButtons from '../../components/activateNodeButtons/ActivateNodeButtons';

function ActivatePage() {
  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{ mb: 2 }} variant={'h5'} align={'center'}>
          - Activate your node -
        </Typography>
      </Grid>
      <Grid item sm={24}>
        <SetContractAddresses />
      </Grid>
      <Grid item sm={6}>
        <Typography sx={{ mb: 2 }} variant={'h6'} align={'center'}>
          To do this, you must have already staked and set node info. Only
          activate your node once you've confirmed it's running on your machine
          with: <pre>sudo lit os guest instance ps</pre>
        </Typography>
        <ActivateNodeButtons />
      </Grid>
    </Grid>
  );
}

export default ActivatePage;
