import * as yup from 'yup';
import {
  addressSchema,
} from './schema';

const stakingAliasesRequestSchema = yup.object({
  aliasAddress: addressSchema,
});

export default class StakingAliasesInfoRequest {
  public readonly aliasAddress: string;

  constructor({
    aliasAddress,
  }: {
    aliasAddress: string;
  }) {
    this.aliasAddress = aliasAddress;
  }

  async validate(): Promise<void> {
    try {
      await stakingAliasesRequestSchema.validate({
        aliasAddress: this.aliasAddress,
      });
    } catch (e: any) {
      throw new Error(`Invalid staking aliases info request: ${e.message}`);
    }
  }
}
