import { PaletteMode } from '@mui/material';
import { deepPurple, purple } from "@mui/material/colors";

const darkLitTheme = {
  palette: {
    mode: 'dark' as PaletteMode,
    background: {
      default: '#000',
      paper: '#0a132d',
      // paper: 'transparent',
      button: '#0a132d',
      card: 'transparent',
    },
    primary: {
      // main: '#a27bd5'
      main: deepPurple[400],
      card: 'transparent'
    },
  },
};

const lightLitTheme = {
  palette: {
    mode: 'light' as PaletteMode,
    background: {
      paper: ''
    },
    primary: {
      main: '#3d2f8c',
      card: '#fff'
    }
  }
};

export { darkLitTheme, lightLitTheme };
