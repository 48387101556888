import { Grid, Typography } from "@mui/material";
import SetContractAddresses from "../../components/setContractAddresses/SetContractAddresses";
import UpdateValidatorInfoForm from "../../components/updateValidatorInfoForm/UpdateValidatorInfoForm";

function UpdateValidatorPage() {
  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{mb: 2}} variant={'h5'} align={'center'}>- Update Validator -</Typography>
      </Grid>
      <Grid item sm={24}>
        <SetContractAddresses/>
      </Grid>
      <Grid item sm={6}>
        <UpdateValidatorInfoForm/>
      </Grid>
    </Grid>
  )
}

export default UpdateValidatorPage;