import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { requestToJoin } from '../../services/staking';
import { useRecoilState } from 'recoil';
import { contractAddressesState } from '../../context/appContext';

function ActivateNodeButtons() {
  const [stakingContractAddress, _] = useRecoilState(contractAddressesState);

  const handleJoin = async () => {
    try {
      await requestToJoin(stakingContractAddress.stakingAddress);
    } catch (e: any) {
      console.log('Error requesting to join: ', e);
      alert('Error requesting to join: ' + e.message);
    }
  };

  return (
    <Card>
      <CardContent>
        <Button onClick={handleJoin} variant={'contained'}>
          Activate Node
        </Button>
      </CardContent>
    </Card>
  );
}

export default ActivateNodeButtons;
