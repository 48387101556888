import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card, CardContent,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { TextFormField } from "../../reusableComponents/TextFormField";
import { Fragment, ChangeEvent } from "react";
import SwitchFormField from "../../reusableComponents/SwitchFormField";

function NodeConfig({
  nodeConfigForm,
  setNodeConfigForm,
}: { nodeConfigForm: any, setNodeConfigForm: any, handleGetConfig: any, handleSetConfig: any, handleExportRpcConfig: any, handleImportRpcConfig: any }) {

  // note: keep around in case of changing field names
  // const formatKeyName = (key: string) => {
  //   const splitOnPeriod = key.split('.');
  //   const splitOnUnderscore = splitOnPeriod.map(word => word.split('_'));
  //   const flattenSplit = splitOnUnderscore.flat();
  //   for (var i = 1; i < flattenSplit.length; i++) {
  //     flattenSplit[i] = flattenSplit[i].charAt(0).toUpperCase() + flattenSplit[i].slice(1);
  //   }
  //   flattenSplit.push('State')
  //   const keyName = flattenSplit.join('')
  //   return keyName;
  // }

  const handleFormChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string | undefined): void => {
    // look for target.value, if that doesn't exist than its a switch
    let updatedValue: string | boolean;
    if (type === 'switch') {
      // @ts-ignore
      updatedValue = e.target.checked;
    }
    if (type === 'text') {
      updatedValue = e.target.value;
    }
    setNodeConfigForm((prevState: any) => {
      const updatedForm = {
        ...prevState,
        [e.target.name]: updatedValue
      }
      return updatedForm;
    })
  }

  return (
    // <Card sx={{borderRadius: '0 0 4px 4px',}}>
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'} spacing={2}>
          <Typography variant={'h6'}>Node Config</Typography>
        </Stack>

      </AccordionSummary>
      <AccordionDetails>
        <Fragment>
          {!nodeConfigForm ? (
            <Typography variant={'body1'}>No config found</Typography>
          ) : (
            <form>
              <Grid container spacing={2}>
                {Object.keys(nodeConfigForm).map((field: any, index: number) => {
                  if (typeof nodeConfigForm[field] === 'string') {
                    return (<TextFormField key={index}
                      setValue={handleFormChange}
                      value={nodeConfigForm[field]}
                      label={field}
                      name={field}
                    />)
                  }
                  if (typeof nodeConfigForm[field] === 'boolean') {
                    return (
                      <SwitchFormField key={index}
                        setValue={handleFormChange}
                        value={nodeConfigForm[field]}
                        label={field}
                        name={field}
                      />
                    )
                  }
                })}
              </Grid>
            </form>
          )}
          {/*<Stack sx={{mt: 2}} direction={'row'} spacing={2}>*/}
          {/*  <Button onClick={handleGetConfig}>Get Config</Button>*/}
          {/*  <Button variant={'contained'} onClick={handleSetConfig}>Set Config</Button>*/}
          {/*</Stack>*/}

        </Fragment>
      </AccordionDetails>
    </Accordion>
    // </Card>
  )
}

export default NodeConfig;
