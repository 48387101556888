import { nacl } from '@lit-protocol/nacl';
import { uint8arrayToString } from '@lit-protocol/uint8arrays';
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useRecoilState } from "recoil";
import { receiverComsKeyPairState, senderComsKeyPairState } from "../../context/appContext";

export default function GenerateCommunicationEncryptionKeys() {
  const [senderComsKeyPair, setSenderComsKeyPair] = useRecoilState(senderComsKeyPairState);
  const [receiverComsKeyPair, setReceiverComsKeyPair] = useRecoilState(receiverComsKeyPairState);

  const handleOnGenerate = () => {
    // Generate two new ed25519 key pairs
    const senderKeyPair = nacl.box.keyPair();
    const receiverKeyPair = nacl.box.keyPair();

    const senderComsKeyPairHolder = {
      publicKey: `0x${
        uint8arrayToString(senderKeyPair.publicKey, 'base16') as string
      }`,
      secretKey: `0x${
        uint8arrayToString(senderKeyPair.secretKey, 'base16') as string
      }`,
    };

    const receiverComsKeyPairHolder = {
      publicKey: `0x${
        uint8arrayToString(receiverKeyPair.publicKey, 'base16') as string
      }`,
      secretKey: `0x${
        uint8arrayToString(receiverKeyPair.secretKey, 'base16') as string
      }`,
    };

    setSenderComsKeyPair(senderComsKeyPairHolder);
    setReceiverComsKeyPair(receiverComsKeyPairHolder);
  };

  const handleOnDownload = () => {
    if (!senderComsKeyPair || !receiverComsKeyPair) {
      return;
    }

    const element = document.createElement('a');
    const file = new Blob(
      [
        JSON.stringify({
          sender: senderComsKeyPair,
          receiver: receiverComsKeyPair,
        }),
      ],
      {
        type: 'application/json',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = 'coms-keys.json';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{m: 2}}>
          <Typography variant={'h5'}>
            Generate Communication Encryption Keys
          </Typography>
          <Button fullWidth variant={'contained'} onClick={handleOnGenerate}>
            Generate
          </Button>
          {!!senderComsKeyPair && !!receiverComsKeyPair && (
            <>
              <Typography variant={'h6'}>Sender</Typography>
              <TextField
                fullWidth
                size={'small'}
                value={senderComsKeyPair.publicKey}
                label={'Public Key'}
                disabled
              />
              <TextField
                fullWidth
                size={'small'}
                value={senderComsKeyPair.secretKey}
                label={'Secret Key'}
                disabled
              />
              <Typography variant={'h6'}>Receiver</Typography>
              <TextField
                fullWidth
                size={'small'}
                value={receiverComsKeyPair.publicKey}
                label={'Public Key'}
                disabled
              />
              <TextField
                fullWidth
                size={'small'}
                value={receiverComsKeyPair.secretKey}
                label={'Secret Key'}
                disabled
              />
              <Button
                fullWidth
                variant={'contained'}
                onClick={handleOnDownload}
              >
                Download
              </Button>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
