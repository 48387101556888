import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import UpdateValidatorInfoRequest from '../../models/UpdateValidateInfoRequest';
import { setIpPortNodeAddressAndCommunicationPubKeys } from '../../services/staking';
import { useRecoilState } from 'recoil';
import { contractAddressesState } from '../../context/appContext';

export default function UpdateValidatorInfoForm() {
  const [stakingContractAddress, _] = useRecoilState(contractAddressesState);

  const handleUpdateValidatorInfo = async (
    request: UpdateValidatorInfoRequest
  ) => {
    try {
      await setIpPortNodeAddressAndCommunicationPubKeys(
        request,
        stakingContractAddress.stakingAddress
      );
    } catch (e: any) {
      console.log('Error updating validator info: ', e);
      alert('Error updating validator info: ' + e.message);
    }
  };

  const [ip, setIp] = useState<string>('');
  const [ipv6, setIpv6] = useState<string>('0');
  const [port, setPort] = useState<string>('');
  const [nodeAddress, setNodeAddress] = useState<string>('');
  const [senderPubKey, setSenderPubKey] = useState<string>('');
  const [receiverPubKey, setReceiverPubKey] = useState<string>('');

  const [validationError, setValidationError] = useState<string | null>(null);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ m: 2 }}>
          <Typography variant={'h5'}>Update Validator Info</Typography>
          <TextField
            fullWidth
            size={'small'}
            value={ip}
            onChange={(e) => setIp(e.target.value)}
            label={'IP'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={ipv6}
            onChange={(e) => setIpv6(e.target.value)}
            label={'IPv6 (not implemented)'}
            disabled
          />
          <TextField
            fullWidth
            size={'small'}
            value={port}
            onChange={(e) => setPort(e.target.value)}
            label={'Port'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={nodeAddress}
            onChange={(e) => setNodeAddress(e.target.value)}
            label={'Node Address'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={senderPubKey}
            onChange={(e) => setSenderPubKey(e.target.value)}
            label={'Sender Pub Key'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={receiverPubKey}
            onChange={(e) => setReceiverPubKey(e.target.value)}
            label={'Receiver Pub Key'}
          />
          <Button
            fullWidth
            variant={'contained'}
            onClick={async () => {
              // Construct request
              const request = new UpdateValidatorInfoRequest({
                ip: ip,
                ipv6: ipv6,
                port: port,
                nodeAddress: nodeAddress,
                senderPubKey: senderPubKey,
                receiverPubKey: receiverPubKey,
              });

              // Validate request
              try {
                await request.validate();
                setValidationError(null);
              } catch (error: any) {
                setValidationError(error.message);
                return;
              }

              // Send request
              await handleUpdateValidatorInfo(request);
            }}
          >
            Update Validator Info
          </Button>
          {validationError && (
            <Typography color={'error'}>{validationError}</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export interface UpdateValidatorInfoFormProps {
  onSubmit: (request: UpdateValidatorInfoRequest) => void;
}
