import { ethers } from 'ethers';

export function ip2int(ip: string) {
  return (
    ip.split('.').reduce(function (ipInt, octet) {
      return (ipInt << 8) + parseInt(octet, 10);
    }, 0) >>> 0
  );
}

export function int2ip(ipInt: number) {
  return (
    ((ipInt >> 24) & 255) +
    '.' +
    ((ipInt >> 16) & 255) +
    '.' +
    ((ipInt >> 8) & 255) +
    '.' +
    (ipInt & 255)
  );
}

const GAS_MULTIPLIER = parseInt(process.env.GAS_MULTIPLIER || '1.5');

export async function getAdjustedGas({
  signer,
  txData,
  multiplier,
}: {
  signer: ethers.providers.JsonRpcSigner;
  txData: ethers.providers.TransactionRequest;
  multiplier?: number;
}) {
  const _multiplier = multiplier || GAS_MULTIPLIER;

  const _estimateGas = await signer.estimateGas(txData);
  const _adjustedGas = _estimateGas.mul(_multiplier);
  return _adjustedGas;
}
