import { Grid, Typography } from "@mui/material";
import GenerateCommunicationEncryptionKeys
  from "../../components/generateCommunicationEncryptionKeys/GenerateCommunicationsEncryptionKeys";

function MiscUtilitiesPage() {
  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{mb: 2}} variant={'h5'} align={'center'}>- Misc Utilities -</Typography>
      </Grid>
      <Grid item sm={12}>
        <GenerateCommunicationEncryptionKeys/>
      </Grid>
    </Grid>
  )
}

export default MiscUtilitiesPage;