export const stringifyNodeConfigBooleans = (obj: any) => {
  let nodeConfigObj = { ...obj };
  Object.entries(nodeConfigObj).map(([key, value]: any, index: number) => {
    if (typeof value === 'boolean') {
      return (nodeConfigObj[key] = JSON.stringify(value));
    } else {
      return (nodeConfigObj[key] = value);
    }
  });
  return nodeConfigObj;
};

export const parseNodeConfigBooleans = (obj: any) => {
  let nodeConfigObj = { ...obj };
  let formattedNodeConfig: any = {};
  Object.entries(nodeConfigObj).forEach(([key, value]: any, index: number) => {
    if (
      (value === 'true' || value === 'false') &&
      typeof JSON.parse(value) === 'boolean'
    ) {
      formattedNodeConfig[key] = JSON.parse(value);
    } else {
      formattedNodeConfig[key] = value;
    }
  });
  return formattedNodeConfig;
};
