import axios from 'axios';
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';
import { Base64 } from 'js-base64';
import { RpcConfig } from './context/appContextInterfaces';

function createSiweMessage(address: string, statement: string) {
  // set expiration time to 1 minute from now in iso8601 format
  const expirationTime = new Date(Date.now() + 1000 * 60).toISOString();
  const message = new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    uri: window.location.origin,
    version: '1',
    chainId: 1,
    expirationTime,
    resources: ['litNodeAdmin://*'],
  });
  return message.prepareMessage();
}

function connectWallet(provider: ethers.providers.JsonRpcProvider) {
  return provider
    .send('eth_requestAccounts', [])
    .catch(() => console.log('user rejected request'));
}

export async function getSignature() {
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  await connectWallet(provider);

  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const message = createSiweMessage(
    address,
    'Sign in with Ethereum to use the Lit Node Admin Panel'
  );
  const sig = await signer.signMessage(message);
  return {
    sig,
    derivedVia: 'web3.eth.personal.sign',
    signedMessage: message,
    address,
  };
}

export async function getBlinders(nodeAddress: string) {
  const authSig = await getSignature();
  return axios.post(`${nodeAddress}/web/admin/get_blinders`, { authSig });
}

export async function getConfig(nodeAddress: string) {
  const authSig = await getSignature();
  return axios.post(`${nodeAddress}/web/admin/get`, { authSig });
}

export async function setConfig(
  nodeAddress: string,
  newConfig: any,
  rpcConfig: RpcConfig
) {
  const authSig = await getSignature();
  console.log('setting config', newConfig, rpcConfig);
  return axios.post(`${nodeAddress}/web/admin/set`, {
    authSig,
    newConfig,
    rpcConfig: { chains: rpcConfig }, // to conform to the rust model
  });
}

export async function setKeyBackup(nodeAddress: string, file: File) {
  const authSig = await getSignature();

  return axios.post(`${nodeAddress}/web/admin/set_key_backup`, file, {
    headers: {
      'x-auth-sig': Base64.encode(JSON.stringify(authSig)),
      'Content-Type': 'application/octet-stream',
    },
  });
}

export const downloadFile = (url: any, authSig: any) => {
  const authBody = Base64.encode(JSON.stringify({ authSig }));

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = url + '?auth=' + authBody;
  link.setAttribute('target', '_blank');

  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
};
