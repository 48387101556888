import { Box, IconButton, Stack, Typography } from "@mui/material";
import LitLogoWhite from "../../assets/litlogowhite.svg";
import LitLogoBlack from "../../assets/litlogoblack.svg";
import { DarkMode, Menu } from "@mui/icons-material";

function Navbar({darkMode, switchTheme, setDrawerOpen, currentPage}: any) {
  return (
    <Box sx={{padding: 0, position: 'relative'}}>
      <Stack
        sx={{p: 2}}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <IconButton sx={{width: '48px', height: '48px'}} onClick={setDrawerOpen}>
          <Menu/>
        </IconButton>
        <Stack spacing={2} direction={'row'} alignItems={'center'}>
          <img
            src={darkMode ? LitLogoWhite : LitLogoBlack}
            className={'nav-logo'}
          />
          <Typography variant={'h4'}>Lit Node Admin Panel</Typography>
        </Stack>
        <IconButton sx={{width: '48px', height: '48px'}} onClick={() => switchTheme()}>
          <DarkMode/>
        </IconButton>
      </Stack>
    </Box>
  )
}

export default Navbar;