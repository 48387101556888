import { Button, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { ethers } from "ethers";
import { useRecoilState } from "recoil";
import { litTokenContractAddressState, nodeWalletState } from "../../context/appContext";
import { getSigner } from "../../lib/web3";
import { useState } from "react";

export default function GenerateNodeWallet() {
    const [nodeWallet, setNodeWallet] = useRecoilState(nodeWalletState);
    const [litTokenContractAddress, setLitTokenContractAddress] = useRecoilState(litTokenContractAddressState);
    const [fundAmount, setFundAmount] = useState<string>('0');

    const handleOnGenerate = async () => {
        const wallet = ethers.Wallet.createRandom();
        setNodeWallet({ address: wallet.address, publicKey: wallet.publicKey, privateKey: wallet.privateKey });
    }

    const handleFundWallet = async () => {
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      const signer = await getSigner();
      const connectedStakerAddress = await signer.getAddress();

      const tx = {
        from: connectedStakerAddress,
        to: nodeWallet?.address,
        value: ethers.utils.parseEther(fundAmount),
        nonce: await provider.getTransactionCount(connectedStakerAddress, "latest"),
      };

      signer.sendTransaction(tx).then((transaction) => {
        console.log(transaction);
        alert("Send finished!");
      });
    }

    const handleOnDownload = () => {
      if (!nodeWallet) {
        return;
      }

      const element = document.createElement('a');
      const file = new Blob(
        [
          JSON.stringify({
            nodeWallet,
          }),
        ],
        {
          type: 'application/json',
        }
      );
      element.href = URL.createObjectURL(file);
      element.download = 'node-wallet.json';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    };

    return (
        <Card>
          <CardContent>
            <Stack spacing={2} sx={{m: 2}}>
                <Typography variant={'h5'}>
                  Generate Node Wallet
                </Typography>
                <Button fullWidth variant={'contained'} onClick={handleOnGenerate}>
                  Generate
                </Button>

                {!!nodeWallet && (
                  <>
                    <Typography variant={'h6'}>Node Wallet</Typography>
                    <TextField
                      fullWidth
                      size={'small'}
                      value={nodeWallet.address}
                      label={'Wallet Address'}
                      disabled
                    />
                    <TextField
                      fullWidth
                      size={'small'}
                      value={nodeWallet.privateKey}
                      label={'Private Key'}
                      disabled
                    />
                    <Button
                      fullWidth
                      variant={'contained'}
                      onClick={handleOnDownload}
                    >
                      Download
                    </Button>
                    <Typography variant={'h6'}>Fund Node Wallet</Typography>
                    <TextField
                      fullWidth
                      size={'small'}
                      value={fundAmount}
                      label={'Amount'}
                      type="number"
                      onChange={e => setFundAmount(e.target.value)}
                    />
                    <Button
                      fullWidth
                      variant={'contained'}
                      onClick={handleFundWallet}
                    >
                      Fund
                    </Button>
                  </>
                )}
            </Stack>
          </CardContent>
        </Card>
    );
}