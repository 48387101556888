import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { requestToLeave, unstakeAndGetRewards } from '../../services/staking';
import { useRecoilState } from 'recoil';
import { contractAddressesState } from '../../context/appContext';

function StakingButtons() {
  const [stakingContractAddress, _] = useRecoilState(contractAddressesState);

  const handleUnstakeAndGetRewards = async () => {
    try {
      await unstakeAndGetRewards(stakingContractAddress.stakingAddress);
    } catch (e: any) {
      console.log('Error unstaking and getting rewards: ', e);
      alert('Error unstaking and getting rewards: ' + e.message);
    }
  };

  const handleRequestToLeave = async () => {
    try {
      await requestToLeave(stakingContractAddress.stakingAddress);
    } catch (e: any) {
      console.log('Error requesting to leave: ', e);
      alert('Error requesting to leave: ' + e.message);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ m: 2 }}>
          <Typography variant={'h5'}>Other Staking Utilities</Typography>
          <Button onClick={handleUnstakeAndGetRewards} variant={'contained'}>
            Unstake and Get Rewards
          </Button>
          <Button onClick={handleRequestToLeave} variant={'contained'}>
            Request To Leave
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default StakingButtons;
