import { ethers } from 'ethers';

export const CHRONICLE_NETWORK_ID = '0x2AC49';
export const VESUVIUS_NETWORK_ID = '0x907';
export const YELLOWSTONE_NETWORK_ID = '0x2ac54';

export const getSigner = async () => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);

  // MetaMask requires requesting permission to connect users accounts
  await provider.send('eth_requestAccounts', []);

  // The MetaMask plugin also allows signing transactions to
  // send ether and pay to change state within the blockchain.
  // For this, you need the account signer...
  const signer = provider.getSigner();
  return signer;
};

export const getProvider = async () => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);

  // MetaMask requires requesting permission to connect users accounts
  await provider.send('eth_requestAccounts', []);

  return provider;
};

export const switchToChronicleNetwork = async () => {
  const { ethereum } = window;
  if (!ethereum) {
    throw new Error('No compatible Web3 wallet extension found');
  }

  // Add Chronicle Network if it doesn't exist
  await ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: CHRONICLE_NETWORK_ID,
        rpcUrls: ['https://chain-rpc.litprotocol.com/http'],
        chainName: 'Chronicle - Lit Protocol Testnet',
        nativeCurrency: {
          name: 'LIT',
          symbol: 'LIT',
          decimals: 18,
        },
        blockExplorerUrls: ['https://chain.litprotocol.com/'],
      },
    ],
  });
  // Switch to Chronicle Network
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: CHRONICLE_NETWORK_ID }],
  });
};

export const switchToVesuviusNetwork = async () => {
  const { ethereum } = window;
  if (!ethereum) {
    throw new Error('No compatible Web3 wallet extension found');
  }

  // Add Vesuvius Network if it doesn't exist
  await ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: VESUVIUS_NETWORK_ID,
        rpcUrls: ['https://vesuvius-rpc.litprotocol.com'],
        chainName: 'Chronicle Vesuvius - Lit Protocol Testnet',
        nativeCurrency: {
          name: 'tstLPX',
          symbol: 'tstLPX',
          decimals: 18,
        },
        blockExplorerUrls: ['https://vesuvius-explorer.litprotocol.com'],
      },
    ],
  });
  // Switch to Vesuvius Network
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: VESUVIUS_NETWORK_ID }],
  });
};

export const switchToYellowstoneNetwork = async () => {
  const { ethereum } = window;
  if (!ethereum) {
    throw new Error('No compatible Web3 wallet extension found');
  }

  // Add Yellowstone Network if it doesn't exist
  await ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: YELLOWSTONE_NETWORK_ID,
        rpcUrls: ['https://yellowstone-rpc.litprotocol.com'],
        chainName: 'Chronicle Yellowstone - Lit Protocol Testnet',
        nativeCurrency: {
          name: 'tstLPX',
          symbol: 'tstLPX',
          decimals: 18,
        },
        blockExplorerUrls: ['https://yellowstone-explorer.litprotocol.com'],
      },
    ],
  });
  // Switch to Yellowstone Network
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: YELLOWSTONE_NETWORK_ID }],
  });
};
