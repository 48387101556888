import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import StakeAndJoinNodeRequest from '../../models/StakeAndJoinNodeRequest';
import { stakeAndSetInfo } from '../../services/staking';
import { BigNumber, ethers } from 'ethers';
import { LoadingButton } from '@mui/lab';
import { useRecoilState } from 'recoil';
import {
  contractAddressErrorState,
  contractAddressesState,
  litTokenContractAddressState,
  nodeWalletState,
  receiverComsKeyPairState,
  senderComsKeyPairState,
  stakingFormState,
} from '../../context/appContext';

export default function StakeAndSetNodeInfo() {
  const [senderComsKeyPair, setSenderComsKeyPair] = useRecoilState(
    senderComsKeyPairState
  );
  const [receiverComsKeyPair, setReceiverComsKeyPair] = useRecoilState(
    receiverComsKeyPairState
  );
  const [nodeWallet, setNodeWallet] = useRecoilState(nodeWalletState);
  const [stakingFormData, setStakingFormData] =
    useRecoilState(stakingFormState);
  const [stakingContractAddress, _] = useRecoilState(contractAddressesState);
  const [litTokenContractAddress, setLitTokenContractAddress] = useRecoilState(
    litTokenContractAddressState
  );
  const [contractAddressError, setContractAddressError] = useRecoilState(
    contractAddressErrorState
  );

  const [loading, setLoading] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<string>(
    stakingFormData?.amount
      ? ethers.utils.formatEther(stakingFormData.amount)
      : '0'
  );
  const [stakeAmountInWei, setStakeAmountInWei] = useState<string>(
    stakingFormData?.amount ?? '0'
  );
  const [stakeIp, setStakeIp] = useState<string>(stakingFormData?.ip ?? '');
  const [stakeIpv6, setStakeIpv6] = useState<string>(
    stakingFormData?.ipv6 ?? '0'
  );
  const [stakePort, setStakePort] = useState<string>(
    stakingFormData?.port ?? ''
  );
  const [stakeNodeAddress, setStakeNodeAddress] = useState<string>(
    nodeWallet?.address ?? ''
  );
  const [stakeSenderPubKey, setStakeSenderPubKey] = useState<string>(
    senderComsKeyPair?.publicKey ?? ''
  );
  const [stakeReceiverPubKey, setStakeReceiverPubKey] = useState<string>(
    receiverComsKeyPair?.publicKey ?? ''
  );

  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    if (!stakeAmount.length || stakeAmount === '0') {
      setStakeAmountInWei(BigNumber.from('0').toString());
    } else {
      const amountInWei = ethers.utils.parseEther(stakeAmount);
      setStakeAmountInWei(amountInWei.toString());
    }
  }, [stakeAmount]);

  const handleStakeNode = async (request: StakeAndJoinNodeRequest) => {
    setLoading(true);
    console.log('Staking contract address: ' + stakingContractAddress);
    console.log('LIT token contract address: ' + litTokenContractAddress);
    try {
      await stakeAndSetInfo(
        request,
        stakingContractAddress.stakingAddress,
        litTokenContractAddress
      );
      setContractAddressError('');
    } catch (e: any) {
      console.log('Error stakingPage node: ' + e.message);
      setContractAddressError(e.message);
      alert('Error stakingPage node: ' + e.message);
    }
    setLoading(false);
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ m: 2 }}>
          <Typography variant={'h5'}>Stake And Set Node Info</Typography>
          <TextField
            fullWidth
            size={'small'}
            value={stakeAmount}
            onChange={(e) => {
              setStakeAmount(e.target.value);
            }}
            label={'Amount (in LIT)'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakeIp}
            onChange={(e) => setStakeIp(e.target.value)}
            label={'IP'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakeIpv6}
            onChange={(e) => setStakeIpv6(e.target.value)}
            label={'IPv6 (not implemented)'}
            disabled
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakePort}
            onChange={(e) => setStakePort(e.target.value)}
            label={'Port'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakeNodeAddress}
            onChange={(e) => setStakeNodeAddress(e.target.value)}
            label={'Node Address'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakeSenderPubKey}
            onChange={(e) => setStakeSenderPubKey(e.target.value)}
            label={'Sender Pub Key'}
          />
          <TextField
            fullWidth
            size={'small'}
            value={stakeReceiverPubKey}
            onChange={(e) => setStakeReceiverPubKey(e.target.value)}
            label={'Receiver Pub Key'}
          />
          <LoadingButton
            loading={loading}
            fullWidth
            variant={'contained'}
            onClick={async () => {
              // Construct request
              const stakingForm = {
                amount: stakeAmountInWei,
                ip: stakeIp,
                ipv6: stakeIpv6,
                port: stakePort,
                nodeAddress: stakeNodeAddress,
                senderPubKey: stakeSenderPubKey,
                receiverPubKey: stakeReceiverPubKey,
              };
              console.log('stakingForm: ', stakingForm);
              const stakeAndJoinNodeRequest = new StakeAndJoinNodeRequest(
                stakingForm
              );

              // Validate request
              try {
                await stakeAndJoinNodeRequest.validate();
                setValidationError(null);
              } catch (error: any) {
                setValidationError(error.message);
                return;
              }

              // Send request
              await handleStakeNode(stakeAndJoinNodeRequest);
              setStakingFormData(stakingForm);
            }}
          >
            Stake And Set Node Info
          </LoadingButton>
          {!!validationError && (
            <Typography color={'error'}>{validationError}</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export interface StakeFormProps {
  onSubmit: (stakeAndJoinNodeRequest: StakeAndJoinNodeRequest) => void;
}
