import { atom } from 'recoil';
import {
  networkNames,
  networks,
} from '../components/setContractAddresses/utils';
import {
  ComsKeys,
  NodeWallet,
  RpcConfig,
  StakingAddresses,
  StakingForm,
} from './appContextInterfaces';

// state for Node Config page
// TODO: update type
export const configInBrowserState = atom<any>({
  key: 'configInBrowserState',
  default: null,
  // default: {
  //   'blockchain.chain_id': '',
  //   'blockchain.chain_name': '',
  //   'blockchain.wallet.default.private_key': '',
  //   'node.admin_address': '',
  //   'node.enable_actions_allowlist': false,
  //   'node.enable_ecdsa_dkg': true,
  //   'node.enable_epoch_transitions': false,
  //   'node.enable_rate_limiting': false,
  //   'node.gpg_pubkey': '',
  //   'node.rpc_url': '',
  //   'node.staker_address': '',
  // }
});

export const rpcConfigState = atom<RpcConfig>({
  key: 'chainsState',
  default: {},
});

export const nodeAddressState = atom<string>({
  key: 'nodeAddressState',
  default: 'https://your.url.here',
});

export const isFilePickedState = atom<boolean>({
  key: 'isFilePickedState',
  default: false,
});

export const selectedFileState = atom<File | null>({
  key: 'selectedFileState',
  default: null,
});

// state for Staking & Staking Aliases page
export const contractAddressesState = atom<StakingAddresses>({
  key: 'contractAddressesState',
  default: networks.manzano,
});

export const litTokenContractAddressState = atom<string>({
  key: 'litTokenContractAddressState',
  default: '',
});

export const contractAddressErrorState = atom<string>({
  key: 'setContractAddressErrorState',
  default: '',
});

export const senderComsKeyPairState = atom<ComsKeys | null>({
  key: 'senderComsKeyPairState',
  default: null,
});

export const receiverComsKeyPairState = atom<ComsKeys | null>({
  key: 'receiverComsKeyPairState',
  default: null,
});

export const nodeWalletState = atom<NodeWallet | null>({
  key: 'nodeWalletState',
  default: null,
});

export const stakingFormState = atom<StakingForm>({
  key: 'stakingFormState',
  default: {
    amount: '',
    ip: '',
    ipv6: '0',
    port: '',
  },
});
