import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  ThemeProvider,
  Typography,
} from '@mui/material';
import './App.css';
import { darkLitTheme, lightLitTheme } from './litTheme';
import Navbar from './components/navbar/Navbar';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import NodeConfigPage from './pages/nodeConfigPage/NodeConfigPage';
import StakingPage from './pages/stakingPage/StakingPage';
import UpdateValidatorPage from './pages/updateValidatorPage/UpdateValidatorPage';
import MiscUtilitiesPage from './pages/miscUtilitiesPage/MiscUtilitiesPage';
import ActivatePage from './pages/activatePage/ActivatePage';
import OnchainNodeInfoPage from './pages/onchainNodeInfoPage/OnchainNodeInfoPage';
import {
  ChevronLeft,
  MiscellaneousServices,
  MonetizationOn,
  PermDataSetting,
  SystemUpdateAlt,
  CurrencyExchange,
  Start,
  Info
} from '@mui/icons-material';
import { fullResObj } from './stubData/stubData';
import { useRecoilState } from 'recoil';
import { rpcConfigState, configInBrowserState } from './context/appContext';
import StakingAliasesPage from './pages/stakingAliases/StakingAliasesPage';

const darkTheme = createTheme(darkLitTheme);
const lightTheme = createTheme(lightLitTheme);

const drawerLinks = [
  {
    name: 'Node Config',
    link: 'node-config',
    icon: <PermDataSetting />,
  },
  {
    name: 'Staking',
    link: 'staking',
    icon: <MonetizationOn />,
  },
  {
    name: 'Update Validator',
    link: 'update-validator',
    icon: <SystemUpdateAlt />,
  },
  {
    name: 'Activate Node',
    link: 'activate-node',
    icon: <Start />,
  },
  {
    name: 'Onchain Node Info',
    link: 'onchain-node-info',
    icon: <Info />,
  },
  {
    name: 'Misc Utilities',
    link: 'misc-utilities',
    icon: <MiscellaneousServices />,
  },
  {
    name: 'Staking Aliases',
    link: 'staking-aliases',
    icon: <CurrencyExchange />,
  },
];

function App() {
  const [rpcConfig, setRpcConfig] = useRecoilState(rpcConfigState);
  const [configInBrowser, setConfigInBrowser] =
    useRecoilState(configInBrowserState);
  const [darkMode, setDarkMode] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const navigate = useNavigate();
  const drawerWidth = 270;

  useEffect(() => {
    if (!!process.env.REACT_APP_DEV_NO_BACKEND) {
      console.log('No backend server running, using stub data');
      const { config, rpcConfig } = fullResObj;
      setConfigInBrowser(config);

      setRpcConfig(rpcConfig);
    }
    // sets light and dark theme if it exists
    const theme = localStorage.getItem('lit-admin-config-dark-mode');
    if (!!theme) {
      setDarkMode(theme === 'dark');
    }
  }, []);

  const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
  })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }));

  const switchTheme = () => {
    const updatedDarkMode = !darkMode;
    setDarkMode(updatedDarkMode);
    localStorage.setItem(
      'lit-admin-config-dark-mode',
      updatedDarkMode ? 'dark' : 'light'.toString()
    );
  };

  const handleUpdatePage = (page: string): void => {
    navigate(page);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Container
        sx={{ pb: 4 }}
        maxWidth={false}
        className={darkMode ? 'App dark-mode-background' : 'App'}
      >
        <Drawer
          anchor={'left'}
          variant={'persistent'}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box
            role={'presentation'}
            sx={{ width: drawerWidth, height: '100%' }}
          >
            <Stack
              sx={{ px: 2, pt: 2 }}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography sx={{ textDecoration: 'underline' }} variant={'h5'}>
                Menu
              </Typography>
              <IconButton
                sx={{ width: '48px', height: '48px' }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <ChevronLeft />
              </IconButton>
            </Stack>
            <List>
              {drawerLinks.map((drawerLink, i) => {
                return (
                  <ListItem disablePadding key={i}>
                    <ListItemButton
                      onClick={() => handleUpdatePage(drawerLink.link)}
                    >
                      <ListItemIcon>{drawerLink.icon}</ListItemIcon>
                      <ListItemText primary={drawerLink.name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
        <Main open={drawerOpen}>
          <Navbar
            darkMode={darkMode}
            switchTheme={switchTheme}
            setDrawerOpen={() => setDrawerOpen(!drawerOpen)}
          />
          <Routes>
            <Route path="/" element={<NodeConfigPage />} />
            <Route path="node-config" element={<NodeConfigPage />} />
            <Route path="staking" element={<StakingPage />} />
            <Route path="update-validator" element={<UpdateValidatorPage />} />
            <Route path="misc-utilities" element={<MiscUtilitiesPage />} />
            <Route path="staking-aliases" element={<StakingAliasesPage />} />
            <Route path="activate-node" element={<ActivatePage />} />
            <Route path="onchain-node-info" element={<OnchainNodeInfoPage />} />

            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            {/*<Route path="*" element={<NoMatch />} />*/}
          </Routes>

          <Outlet />
        </Main>
      </Container>
    </ThemeProvider>
  );
}

export default App;
