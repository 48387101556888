import { ethers } from 'ethers';
import stakingContractJSON from '../../abis/Staking.json';
import { StakingAddresses } from '../../context/appContextInterfaces';

export enum chainNames {
  CHRONICLE = 'chronicle',
  VESUVIUS = 'vesuvius',
  YELLOWSTONE = 'yellowstone',
}

const litChains = {
  chronicle: {
    name: chainNames.CHRONICLE,
    rpcUrl: 'https://lit-protocol.calderachain.xyz/http',
    explorer: 'https://lit-protocol.calderaexplorer.xyz',
  },
  vesuvius: {
    name: chainNames.VESUVIUS,
    rpcUrl: 'https://vesuvius-rpc.litprotocol.com',
    explorer: 'https://vesuvius-explorer.litprotocol.com',
  },
  yellowstone: {
    name: chainNames.YELLOWSTONE,
    rpcUrl: 'https://yellowstone-rpc.litprotocol.com',
    explorer: 'https://yellowstone-explorer.litprotocol.com',
  },
};

export enum networkNames {
  HABANERO = 'habanero',
  MANZANO = 'manzano',
  CUSTOM = 'custom',
  DATIL_TEST = 'datil_test',
  DATIL = 'datil',
  DATIL_CLONE = 'datil_clone',
}

export const networks = {
  habanero: {
    network: networkNames.HABANERO,
    stakingAddress: '0xde8627067188C0063384eC682D9187c7d7673934',
    stakingBalancesAddress: '0x50f6722544937b72EcaDFDE3386BfdDbdBB3103B',
    chain: litChains.chronicle,
  },
  manzano: {
    network: networkNames.MANZANO,
    stakingAddress: '0xBC7F8d7864002b6629Ab49781D5199C8dD1DDcE1',
    stakingBalancesAddress: '0x82F0a170CEDFAaab623513EE558DB19f5D787C8D',
    chain: litChains.chronicle,
  },
  custom: {
    network: networkNames.CUSTOM,
    stakingAddress: '',
    stakingBalancesAddress: '',
    chain: litChains.yellowstone,
  },
  datil_test: {
    network: networkNames.DATIL_TEST,
    stakingAddress: '0xdec37933239846834b3BfD408913Ed3dbEf6588F',
    stakingBalancesAddress: '0xCa3c64e7D8cA743aeD2B2d20DCA3233f400710E2',
    chain: litChains.yellowstone,
  },
  datil: {
    network: networkNames.DATIL,
    stakingAddress: '0x21d636d95eE71150c0c3Ffa79268c989a329d1CE',
    stakingBalancesAddress: '0x9c9D147dad75D8B9Bd327405098D65C727296B54',
    chain: litChains.yellowstone,
  },
  datil_clone: {
    network: networkNames.DATIL_CLONE,
    stakingAddress: '0x5758aDa5a1dC05e659eF0B5062fbcF093Ec572D1',
    stakingBalancesAddress: '0x03b4d7C0A73f7c9346BF8218e7AF430B8ae236Ea',
    chain: litChains.yellowstone,
  },
};

const getLitTokenContractAddress = async (
  stakingContractAddress: StakingAddresses
) => {
  console.log('Resolving network info from chain, 1 sec...');
  const provider = new ethers.providers.JsonRpcProvider(
    stakingContractAddress.chain.rpcUrl
  );

  const stakingContract = new ethers.Contract(
    stakingContractAddress.stakingAddress,
    stakingContractJSON,
    provider
  );

  return await stakingContract.getTokenAddress();
};

export default getLitTokenContractAddress;
