import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { downloadFile, getBlinders, getSignature, setKeyBackup } from '../../api';
import {
  configInBrowserState,
  nodeAddressState,
} from '../../context/appContext';

function GeneralFunctions() {
  const [configInBrowser, setConfigInBrowser] =
    useRecoilState(configInBrowserState);
  const [nodeAddress, setNodeAddress] = useRecoilState(nodeAddressState);

  const [restoreUploading, setRestoreUploading] = useState(false);

  // const handleGetConfig = async () => {
  //   let res;
  //   try {
  //     res = await getConfig(nodeAddress);
  //   } catch (e: any) {
  //     alert(
  //       'error getting config: ' +
  //       e.message +
  //       ' - ' +
  //       e.response.data.error +
  //       ' - ' +
  //       e.response.data.reason
  //     );
  //     throw e;
  //   }
  //   const {data} = res;
  //   console.log('data', data);
  //   const {config, chains} = data;
  //   // sort the config by keyname so that it's consistent otherwise it comes out in a diff order everytime which is super confusing IMO
  //   const orderedConfig = Object.keys(config)
  //     .sort()
  //     .reduce((obj: { [key: string]: string }, key) => {
  //       obj[key] = config[key];
  //       return obj;
  //     }, {});
  //   setConfigInBrowser(orderedConfig);
  //
  //   // sort the rpc config by keyname so that it's consistent otherwise it comes out in a diff order everytime which is super confusing IMO
  //   const orderedChains = Object.keys(chains)
  //     .sort()
  //     .reduce((obj: { [key: string]: string }, key) => {
  //       obj[key] = chains[key];
  //       return obj;
  //     }, {});
  //   setChains(orderedChains);
  // };
  //
  // const handleSetConfig = async () => {
  //   let res;
  //   try {
  //     res = await setConfig(nodeAddress, configInBrowser, chains);
  //   } catch (e: any) {
  //     alert(
  //       'error getting config: ' +
  //       e.message +
  //       ' - ' +
  //       e.response.data.error +
  //       ' - ' +
  //       e.response.data.reason
  //     );
  //     throw e;
  //   }
  //
  //   const {data} = res;
  //   console.log('data', data);
  // };

  const handleGetBlinders = async () => {
    let res;
    try {
      console.log('nodeAddress', nodeAddress);
      res = await getBlinders(nodeAddress);
    } catch (e: any) {
      alert(
        'error getting blinders: ' +
          e.message +
          ' - ' +
          e.response.data.error +
          ' - ' +
          e.response.data.reason
      );
      throw e;
    }
    const { data } = res;
    const { success, ...blinders } = data;

    const element = document.createElement('a');
    const file = new Blob(
      [
        JSON.stringify(
          blinders
        ),
      ],
      {
        type: 'application/json',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = 'node-blinders.json';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleGetKeyBackup = async () => {
    const authSig = await getSignature();

    downloadFile(`${nodeAddress}/web/admin/get_key_backup`, authSig);
  };

  const handleSetKeyBackup = async (event: any) => {
    setRestoreUploading(true);
    console.log('Setting key backup file: ', event.target.files[0]);
    try {
      await setKeyBackup(nodeAddress, event.target.files[0]);
      setRestoreUploading(false);
      console.log('Set key backup successfully');
    } catch (e: any) {
      setRestoreUploading(false);
      alert('Error setting key backup: ' + e.message);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
            <Typography sx={{ mr: '2em' }} variant={'h5'}>
              Node Address to talk to
            </Typography>
            <Stack sx={{ pr: '2em' }} direction={'row'} spacing={2}>
              <Button onClick={handleGetBlinders}>Blinders</Button>
              <Button onClick={handleGetKeyBackup}>Backup Keys</Button>
              <LoadingButton
                component="label"
                loading={restoreUploading}
                disabled={restoreUploading}
                variant={'contained'}
              >
                Restore Keys Upload File
                <input
                  type="file"
                  hidden
                  accept=".gz"
                  onChange={handleSetKeyBackup}
                />
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
            <TextField
              sx={{ flexGrow: '1', flexShrink: '1' }}
              size={'small'}
              value={nodeAddress}
              onChange={(e) => setNodeAddress(e.target.value)}
              label={'Node Address To Talk To'}
            />
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction={'row'} spacing={2}>
          {/*<Button onClick={handleGetConfig}>Get Config</Button>*/}
          {/*<Button onClick={handleSetConfig}>Set Config</Button>*/}
          {/*<Button onClick={handleGetKeyBackup}>*/}
          {/*  Backup Keys*/}
          {/*</Button>*/}
          {/*<LoadingButton loading={restoreUploading}*/}
          {/*               disabled={restoreUploading}*/}
          {/*               onClick={handleGetKeyBackup}*/}
          {/*               variant={'contained'}*/}
          {/*>*/}
          {/*  Restore Keys*/}
          {/*</LoadingButton>*/}
          {/*<Box sx={{m: 1, position: 'relative'}}>*/}
          {/*  <Button variant="contained" component="label" disabled={restoreUploading}>*/}
          {/*    Restore Keys*/}
          {/*    <input type="file" hidden onInput={handleSetKeyBackup}/>*/}
          {/*  </Button>*/}
          {/*  {restoreUploading &&*/}
          {/*    <CircularProgress*/}
          {/*      size={24}*/}
          {/*      sx={{*/}
          {/*        color: blue[500],*/}
          {/*        position: 'absolute',*/}
          {/*        top: '50%',*/}
          {/*        left: '50%',*/}
          {/*        marginTop: '-12px',*/}
          {/*        marginLeft: '-12px',*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  }*/}
          {/*</Box>*/}
        </Stack>
      </CardActions>
    </Card>
  );
}

export default GeneralFunctions;
